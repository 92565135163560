import Alpine from 'alpinejs'

export const initBrandFilter = () => {
    Alpine.data('brandFilter', () => ({
        firstLoop: true,
        showSubfilters: false,
        models: [] as string[],
        brands: [] as string[],
        brandModels: {} as any,
        init() {
            this.ReadFilterFromURL()
            if (!this.firstLoop) {
                this.$watch('models', () => this.updateModelFilter())
                this.$watch('brands', (value, oldValue) => this.updateBrandFilter(value, oldValue))
            }
            this.showSubfilters = this.brands.length > 0
        },
        updateModelFilter() {
            const event = new CustomEvent('update-filter', {
                detail: {
                    attribute: 'models',
                    value: this.models,
                    query: 'model[]',
                },
            })

            window.dispatchEvent(event)
        },
        updateBrandFilter(value: string[], oldValue: string[]) {
            const difference = oldValue.filter((x) => !value.includes(x))
            let removeModels = []
            let uncheckedBrand = ''

            if (difference.length) {
                uncheckedBrand = difference[0]

                for (const [brand, carModels] of Object.entries(this.brandModels)) {
                    if (brand === uncheckedBrand) {
                        if (Object.keys(carModels).length <= 0) continue
                        carModels.forEach((carModel) => {
                            if (this.models.includes(carModel.label)) {
                                removeModels.push(carModel.label)
                            }
                        })
                    }
                }
            }

            const event = new CustomEvent('update-filter', {
                detail: {
                    attribute: 'brands',
                    value: this.brands,
                    query: 'merk[]',
                    subQuery: 'model[]',
                    subValues: removeModels,
                },
            })

            window.dispatchEvent(event)
        },
        updateFilter() {
            let qp = new URLSearchParams()
            this.models.forEach((model) => qp.append('model[]', model))
            this.brands.forEach((brand) => qp.append('merk[]', brand))
        },
        setBrandModels(brand, models) {
            this.brandModels[brand] = models
        },
        ReadFilterFromURL() {
            let urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get('model[]')) {
                this.models = urlParams.getAll('model[]')
            }
            if (urlParams.get('merk[]')) {
                this.brands = urlParams.getAll('merk[]')
            }

            this.firstLoop = false
        },
    }))
}
