import Alpine from 'alpinejs'

export const initCollapsible = () => {
    Alpine.data('collapsible', (minHeight = 0, expandedState = false, filters = null) => ({
        contentHeight: minHeight ? minHeight : 0,
        active: expandedState,
        init() {
            setTimeout(() => {
                if (expandedState) {
                    this.contentHeight = this.$refs.content.scrollHeight
                }

                if (filters) {
                    const urlParams = new URLSearchParams(window.location.search)
                    const filterId = filters.split('.')
                    let lastFilterId = filterId[filterId.length - 1]
                    lastFilterId = lastFilterId.replace('_pct', '')
                    lastFilterId = lastFilterId.replace('price', 'prijs')

                    if (urlParams.has(lastFilterId) || urlParams.has(lastFilterId + '[]')) {
                        this.toggle()
                    }
                }
            }, 300)

            window.addEventListener(
                'resize',
                this.debounce(() => this.updateContentHeight())
            )
        },
        toggle(event, isOpen = false) {
            if (!isOpen) {
                this.active = !this.active
                this.contentHeight = this.active ? this.$refs.content.scrollHeight : minHeight
            }
        },
        updateContentHeight() {
            if (this.active) {
                this.contentHeight = this.$refs.content.scrollHeight
            }
        },
        debounce(func: Function, timeout = 250) {
            let timer: ReturnType<typeof setTimeout>
            return (...args: any[]) => {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    func.apply(this, args)
                }, timeout)
            }
        },
    }))
}
