import Alpine from 'alpinejs'

export const initVacancyFilter = () => {
    Alpine.data('vacancyFilter', (data = []) => ({
        allFilters: [],
        filteredVacancies: [],
        init() {
            this.allFilters = data.filter((item) => item.vacancyTitle !== 'Open Sollicitatie')
            this.filteredVacancies = this.allFilters
        },
        updateVacancyFilter(event, type) {
            if (event.target.value == 'departments-all' || event.target.value == 'locations-all') {
                this.filteredVacancies = this.allFilters
            } else {
                this.filteredVacancies = this.allFilters.filter((item) => {
                    return Object.keys(item[type]).includes(event.target.value)
                })
            }
        },
        returnFilteredLocations(vacancy) {
            const mappedObject = Object.entries(vacancy.locations).map(([key, value]) => {
                return value.title
            })

            return mappedObject.join(', ')
        },
    }))
}
