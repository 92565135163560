import Alpine from 'alpinejs'

export const initPictureViewer = () => {
    Alpine.data('pictureViewer', (licenseplate, vin) => ({
        theme: 'wealer2024',
        VivitionInitialized: false,
        init() {
            this.checkVivition(this.theme, licenseplate, vin).then(this.mount(this.$refs))
        },
        checkVivition(theme, licenceplate, vin) {
            return new Promise(async (resolve, reject) => {
                if (!licenceplate && !vin) {
                    reject()
                    return
                }

                const checkURL = (url) => {
                    return new Promise((resolve, reject) => {
                        let xhr = new XMLHttpRequest()
                        xhr.open('GET', url, true)
                        let _self = this

                        xhr.onreadystatechange = function () {
                            if (this.readyState === 4) {
                                if (this.status === 200) {
                                    let json = null
                                    try {
                                        json = JSON.parse(this.responseText)
                                    } catch (e) {}
                                    if (json && json.existing) {
                                        _self.VivitionInitialized = true
                                        resolve()
                                    } else reject()
                                } else reject()
                            }
                        }
                        xhr.send()
                    })
                }
                if (licenceplate) {
                    try {
                        await checkURL('https://static-api.vivition.com/' + theme + '/licenceplate_vin/' + licenceplate + '_/existing.json')
                        resolve()
                        return
                    } catch (e) {}
                }
                try {
                    await checkURL('https://static-api.vivition.com/' + theme + '/licenceplate_vin/_' + vin + '/existing.json')
                    resolve()
                } catch (e) {
                    reject()
                }
            })
        },
        mount(refs) {
            refs.pictureRoot.innerHTML =
                '<div ' +
                (vin !== '' ? 'data-vin="' + vin + '"' : '') +
                'data-theme="' +
                this.theme +
                '" class="vivition-player"></div>' +
                '<div class="vivition-thumbbar"></div>' +
                '<style>.vivition-player { height:0;padding-bottom: 66.66% } .vivition-thumbbar { height: 100px;margin-top:4px; } ' +
                'body.vivition-mobile .vivition-thumbbar { height: 65px; } @media(max-width:768px) { .vivition-thumbbar { height: 65px; } }.thumbcontainer { display: block !important }' +
                '.thumbnr {display: none !important;} .vivition-player {background: url(https://static-api.vivition.com/' +
                this.theme +
                '/licenceplate_vin/_' +
                vin +
                '/initialphoto.jpg) no-repeat center / cover;}' +
                '@media screen and (min-width: 768px) {.vivition-player {background: url(https://static-api.vivition.com/' +
                this.theme +
                '/licenceplate_vin/_' +
                vin +
                '/initialphoto_desktop.jpg) no-repeat center / cover; }}' +
                '</style>'

            // Insert the VIVITION Script at the bottom of the body
            let script = document.createElement('script')
            script.setAttribute('type', 'text/javascript')
            script.setAttribute('src', 'https://static-api.vivition.com/scripts/vivition.virtualvehicletour.js')
            script.appendChild(document.createTextNode(''))
            document.body.appendChild(script)
        },
    }))
}
