import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initSlider = () => {
    Alpine.data('initModelSlider', () => ({
        init() {
            new Splide(this.$el, {
                gap: 30,
                perMove: 1,
                perPage: 1,
                isNavigation: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    768: {
                        perPage: 2,
                    },
                    1024: {
                        perPage: 3,
                    },
                    1200: {
                        perPage: 4,
                    },
                },
            }).mount()
        },
    }))

    Alpine.data('initImageSlider', () => ({
        init() {
            new Splide(this.$el, {
                gap: 30,
                isNavigation: false,
                arrows: true,
                pagination: false,
            }).mount()
        },
    }))

    Alpine.data('initEmployeeSlider', () => ({
        init() {
            new Splide(this.$el, {
                gap: 30,
                perMove: 1,
                perPage: 1,
                isNavigation: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    768: {
                        perPage: 2,
                    },
                    1024: {
                        perPage: 3,
                    },
                    1200: {
                        perPage: 4,
                    },
                },
            }).mount()
        },
    }))
}
