import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initVdpImageSlider = () => {
    Alpine.data('initVdpImageSlider', () => ({
        showModel: false,
        show360Model: false,
        zoomCarousel: null as Splide | null,
        mainCarousel: null as Splide | null,
        thumbnails: null as Splide | null,
        init() {
            this.mainCarousel = new Splide('#main-carousel', {
                pagination: false,
                arrows: true,
                paginationKeyboard: true,
                keyboard: true,
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                },
            }).mount()

            this.zoomCarousel = new Splide('#zoom-carousel', {
                pagination: false,
                arrows: true,
                heightRatio: 1,
                keyboard: true,
                paginationKeyboard: true,
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                },
            }).mount()

            this.thumbnails = new Splide('#thumbnail-carousel', {
                fixedWidth: 150,
                gap: 5,
                pagination: false,
                isNavigation: true,
                arrows: false,
            }).mount()

            this.mainCarousel.sync(this.thumbnails)
            this.thumbnails.sync(this.mainCarousel)

            this.mainCarousel.on('move', () => {
                this.zoomCarousel?.go(this.mainCarousel?.index)
            })
        },
        toggleModel() {
            this.showModel = !this.showModel

            this.zoomCarousel?.go(this.mainCarousel?.index)
        },
        toggle360Model() {
            this.show360Model = !this.show360Model
        },
    }))
}
