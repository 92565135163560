import Alpine from 'alpinejs'

interface DropdownFilter {
    firstLoop: boolean
    valueLow: string
    valueHigh: string
    id: string
    init(): void
    updateFilter(): void
    ReadFilterFromURL(): void
}

export const initDropdownFilters = () => {
    Alpine.data('dropdownFilter', (id, valueLow, valueHigh) => ({
        firstLoop: false,
        valueLow: '' as string,
        valueHigh: '' as string,
        id: '' as string,
        init() {
            this.id = id
            this.valueLow = valueLow
            this.valueHigh = valueHigh

            this.ReadFilterFromURL()

            if (!this.firstLoop) {
                this.$watch('valueLow', () => this.updateFilter())
                this.$watch('valueHigh', () => this.updateFilter())
            }
        },
        updateFilter() {
            const updatedId = this.id.replace(/ /g, '_')
            const event = new CustomEvent('update-filter', {
                detail: {
                    attribute: 'dropdown',
                    value: [this.valueLow, this.valueHigh].join('-'),
                    query: updatedId,
                },
            })

            window.dispatchEvent(event)
        },

        ReadFilterFromURL() {
            let urlParams = new URLSearchParams(window.location.search)

            if (urlParams.get(id)) {
                const splitter = urlParams.get(id)?.split('-')
                this.valueLow = splitter[0]
                this.valueHigh = splitter[1]
            }

            this.firstLoop = false
        },
    }))
}
