import Alpine from 'alpinejs'
import Splide from '@splidejs/splide'
import AirDatepicker from 'air-datepicker'
import localeNL from 'air-datepicker/locale/nl'
import JustValidate from 'just-validate'

export const initOnlineafspraken = () => {
    enum AppointmentState {
        NotStarted,
        Started,
        Finished,
        FinishedNotEditable,
    }

    interface Summary {
        step_one: {
            type: string
            brand: string
            model: string
            test_drive: boolean
            buy_type: string
            fuel_type: string
        }
        step_two: {
            location: string
        }
        step_three: {
            textualDate: string
            date: string
            start_time: string
            end_time: string
        }
        step_four: {
            resource: string
        }
        step_five: {
            first_name: string
            last_name: string
            email: string
            address: string
            place: string
            postal_code: string
            phone: string
            notes: string
        }
    }

    const formatDate = (date: Date | null) => {
        if (!date || date.toString() === 'Invalid Date') {
            return ''
        }

        let day = date.getDate().toString().padStart(2, '0')
        let month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
        let year = date.getFullYear()

        return `${year}-${month}-${day}`
    }

    Alpine.data(
        'onlineafspraken',
        (
            appointmentTypeUrl = '',
            bookableTimesUrl = '',
            resourceUrl = '',
            setCustomerUrl = '',
            setAppointmentUrl = '',
            recaptchaSiteKey = '',
            validateRecaptchaUrl = ''
        ) => ({
            appointmentSteps: [
                {
                    step: 1,
                    name: window.translations.stepNames.vehicleStep,
                    state: AppointmentState.Started,
                    icon: 'fa fa-car-side',
                    canContinue: false,
                    title: window.translations.stepTitles.vehicleSelect,
                },
                {
                    step: 2,
                    name: window.translations.stepNames.locationStep,
                    state: AppointmentState.NotStarted,
                    icon: 'fa fa-car-building',
                    canContinue: false,
                    title: window.translations.stepTitles.locationSelect,
                },
                {
                    step: 3,
                    name: window.translations.stepNames.dateTimeStep,
                    state: AppointmentState.NotStarted,
                    icon: 'fa fa-calendar-days',
                    canContinue: false,
                    title: window.translations.stepTitles.dateTimeSelect,
                },
                {
                    step: 4,
                    name: window.translations.stepNames.employeeStep,
                    state: AppointmentState.NotStarted,
                    icon: 'fa fa-user',
                    canContinue: false,
                    title: window.translations.stepTitles.employeeSelect,
                },
                {
                    step: 5,
                    name: window.translations.stepNames.personalDataStep,
                    state: AppointmentState.NotStarted,
                    icon: 'fa fa-table-list',
                    canContinue: false,
                    title: window.translations.stepTitles.personalData,
                },
                {
                    step: 6,
                    hidden: true,
                    state: AppointmentState.FinishedNotEditable,
                },
            ],
            currentStep: 1,
            validLocations: [],
            filteredTimes: [],
            bookableTimes: [],
            availableResources: [],
            summary: {
                step_one: {
                    type: '',
                    brand: '',
                    model: '',
                    test_drive: false,
                    buy_type: '',
                    fuel_type: '',
                },
                step_two: {
                    location: '',
                },
                step_three: {
                    textualDate: '',
                    date: '',
                    start_time: '',
                    end_time: '',
                },
                step_four: {
                    resource: '',
                },
                step_five: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    address: '',
                    place: '',
                    postal_code: '',
                    phone: '',
                    notes: '',
                },
            } as Summary,
            defaultSummary: {} as Summary,
            datePicker: null as AirDatepicker | null,
            isLoading: false,
            isAppointmentLoading: false,
            canSubmit: false,
            recaptchaError: false,
            selectedVehicleBrand: '',
            hasError: '',
            init() {
                this.checkPrefillValues()

                this.defaultSummary = JSON.parse(JSON.stringify(this.summary))

                this.$watch('summary.step_one.brand', () => {
                    this.checkIfCanContinue()
                })
                this.$watch('summary.step_one.buy_type', () => {
                    this.checkIfCanContinue()
                })

                const validator = new JustValidate('#personal-data-panel', { validateBeforeSubmitting: true }, [
                    {
                        key: 'First name is required',
                        dict: {
                            Dutch: 'Voornaam is verplicht',
                        },
                    },
                    {
                        key: 'Last name is required',
                        dict: {
                            Dutch: 'Achternaam is verplicht',
                        },
                    },
                    {
                        key: 'Email is required',
                        dict: {
                            Dutch: 'Email is verplicht',
                        },
                    },
                    {
                        key: 'First name is too short',
                        dict: {
                            Dutch: 'Voornaam is te kort',
                        },
                    },
                    {
                        key: 'Last name is too short',
                        dict: {
                            Dutch: 'Achternaam is te kort',
                        },
                    },
                    {
                        key: 'Needs to be an email address',
                        dict: {
                            Dutch: 'Dit moet een email adres zijn',
                        },
                    },
                ])
                validator
                    .addField(this.$refs['form.first_name'], [
                        {
                            rule: 'required',
                            errorMessage: 'First name is required',
                        },
                        {
                            rule: 'minLength',
                            value: 2,
                            errorMessage: 'First name is too short',
                        },
                    ])
                    .addField(this.$refs['form.last_name'], [
                        {
                            rule: 'required',
                            errorMessage: 'Last name is required',
                        },
                        {
                            rule: 'minLength',
                            value: 2,
                            errorMessage: 'Last name is too short',
                        },
                    ])
                    .addField(this.$refs['form.email'], [
                        {
                            rule: 'required',
                            errorMessage: 'Email is required',
                        },
                        {
                            rule: 'email',
                            errorMessage: 'Needs to be an email address',
                        },
                    ])
                    .onValidate((data: any) => {
                        if (data.isValid) {
                            this.canSubmit = true
                        } else {
                            this.canSubmit = false
                        }
                    })

                validator.setCurrentLocale('Dutch')
            },
            checkPrefillValues() {
                const qp = new URLSearchParams(location.search)
                if (qp.has('ref', 'appointment')) {
                    if (qp.has('brand')) {
                        Array.from(this.$refs.vehicleBrandSelect.options).map((option) => {
                            if (option.label == qp.get('brand')) {
                                this.summary.step_one.brand = {
                                    value: option.value,
                                    text: option.label,
                                }
                            }
                        })
                    }
                    if (qp.has('model')) {
                        this.summary.step_one.model = qp.get('model')
                    }
                }
            },
            checkParam(param: string, value: string) {
                return new URLSearchParams(location.search).has(param, value)
            },
            async fetchAppointmentsByBrand(isUrlQuery = false) {
                if (this.summary.step_one.brand === 'none') return

                this.validLocations = []

                this.isLoading = true

                let splitBrands = []
                if (isUrlQuery) {
                    splitBrands = this.summary.step_one.brand.split(',')
                } else {
                    splitBrands = this.summary.step_one.brand.value.split(',')
                }

                splitBrands.forEach(async (brandId) => {
                    let url = appointmentTypeUrl + `?appointmentTypeId=` + brandId
                    await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.validLocations.push(result.appointmentType)
                                this.appointmentSteps[this.currentStep - 1].canContinue = true
                                this.createLocationsSlider()
                                this.isLoading = false
                            }
                        })
                        .catch((error) => {
                            console.error('Fetch error:', error)
                        })
                })
            },
            async fetchAppointmentLocation(appointmentTypeId: string) {
                let url = bookableTimesUrl + `?appointmentTypeId=` + appointmentTypeId
                this.isLoading = true
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(async (response) => {
                        if (response.ok) {
                            const parsedResponse = await response.json()
                            if (parsedResponse.success) {
                                this.appointmentSteps[this.currentStep - 1].canContinue = true
                                this.bookableTimes = parsedResponse.bookableTimes
                                this.isLoading = false
                                this.updateDatePicker()
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Fetch error:', error)
                    })
            },
            async fetchResources(resourceIds: string[], selectedTimes: string[]) {
                this.invalidateNextSteps()
                const [startTime, endTime] = selectedTimes
                this.summary.step_three.start_time = startTime
                this.summary.step_three.end_time = endTime
                this.availableResources = []
                this.isLoading = true

                resourceIds.forEach(async (resourceId) => {
                    let url = resourceUrl + '?resourceId=' + resourceId
                    await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(async (response) => {
                            if (response.ok) {
                                const parsedResponse = await response.json()
                                if (parsedResponse.success) {
                                    this.summary.step_four.resource = ''

                                    this.appointmentSteps[this.currentStep - 1].canContinue = true
                                    this.availableResources.push(parsedResponse.resource)
                                    this.createEmployeeSlider()
                                    this.isLoading = false
                                }
                            }
                        })
                        .catch((error) => {
                            console.error('Fetch error:', error)
                        })
                })
            },
            isActive(step: number) {
                return this.currentStep === step
            },
            isDone(step: number) {
                step = step - 1
                return (
                    this.appointmentSteps[step].state === AppointmentState.Finished ||
                    this.appointmentSteps[step].state === AppointmentState.FinishedNotEditable
                )
            },
            isActiveAndDone(step: number) {
                return (
                    this.currentStep === step &&
                    (this.appointmentSteps[step - 1].state === AppointmentState.Finished ||
                        this.appointmentSteps[step - 1].state === AppointmentState.FinishedNotEditable)
                )
            },
            isStarted(step: number) {
                step = step - 1
                return this.appointmentSteps[step].state === AppointmentState.Started
            },
            isStartedOrDone(step: number) {
                step = step - 1
                return (
                    this.appointmentSteps[step].state === AppointmentState.Started || this.appointmentSteps[step].state === AppointmentState.Finished
                )
            },
            stepName(step: number) {
                if (step == this.appointmentSteps.length) return
                step = step - 1
                return this.appointmentSteps[step].name.toLowerCase()
            },
            nextStep() {
                this.appointmentSteps[this.currentStep - 1].state = AppointmentState.Finished
                this.appointmentSteps[this.currentStep].state = AppointmentState.Started
                this.currentStep++
            },
            previousStep() {
                this.currentStep--
            },
            navigateStep(step: number) {
                this.currentStep = step
            },
            canNavigateStep(step: number) {
                if (
                    this.appointmentSteps[step - 1].state === AppointmentState.NotStarted ||
                    this.appointmentSteps[step - 1].state === AppointmentState.FinishedNotEditable
                )
                    return false
                this.currentStep = step
                return true
            },
            canContinue() {
                return !this.appointmentSteps[this.currentStep - 1].canContinue
            },
            changeBrand() {
                this.summary.step_one.brand = {
                    value: event.target.selectedOptions[0].value,
                    text: event.target.selectedOptions[0].text,
                }
                this.invalidateNextSteps()
                //Reset all steps because data is invalidated
                this.appointmentSteps.forEach((step) => {
                    if (step.step === 1) {
                        step.state = AppointmentState.Started
                        return
                    }
                    step.canContinue = false
                    step.state = AppointmentState.NotStarted
                    this.validLocations = []
                })
            },

            addActiveStyle(step: number) {
                return this.isActive(step) ? 'online-appointment__step--active' : ''
            },
            addInteractableStyle(step: number) {
                if (this.appointmentSteps[step - 1].state === AppointmentState.FinishedNotEditable) {
                    return ''
                }
                return this.isDone(step) || this.isStarted(step) ? 'online-appointment__step--is-interactable' : ''
            },

            selectLocation(location: string) {
                if (this.summary.step_two.location == location) return
                this.invalidateNextSteps()
                this.summary.step_two.location = location

                this.appointmentSteps[this.currentStep - 1].canContinue = false
                this.fetchAppointmentLocation(this.summary.step_two.location.Id)
            },
            selectResource(resource: string) {
                if (this.summary.step_four.resource == resource) return
                this.invalidateNextSteps()
                this.summary.step_four.resource = resource
                this.appointmentSteps[this.currentStep - 1].canContinue = true
            },

            checkIfCanContinue() {
                if (this.summary.step_one.brand && this.summary.step_one.buy_type) this.fetchAppointmentsByBrand()
            },

            isFinalStep() {
                return this.currentStep == this.appointmentSteps.length
            },

            invalidateNextSteps() {
                for (let index = this.currentStep; index < this.appointmentSteps.length; index++) {
                    this.appointmentSteps[index].canContinue = false
                    this.appointmentSteps[index].state = AppointmentState.NotStarted

                    // Ensure we're resetting only the relevant steps
                    if (index === 1) {
                        this.summary.step_two = { ...this.defaultSummary.step_two }
                    }
                    if (index === 2) {
                        this.summary.step_three = { ...this.defaultSummary.step_three }
                        this.summary.step_four = { ...this.defaultSummary.step_four }
                        this.filteredTimes = []
                    }
                    if (index === 3) {
                        this.summary.step_four = { ...this.defaultSummary.step_four }
                    }
                }
            },

            createLocationsSlider() {
                setTimeout(() => {
                    const splide = new Splide(this.$refs.locationPanel, {
                        gap: 30,
                        perMove: 1,
                        perPage: 4,
                        pagination: false,
                        mediaQuery: 'min',
                        breakpoints: {
                            0: {
                                perPage: 1,
                            },
                            500: {
                                perPage: 2,
                            },
                            1400: {
                                perPage: 3,
                            },
                            1700: {
                                perPage: 4,
                            },
                        },
                    })

                    splide.on('overflow', function (isOverflow) {
                        splide.options = {
                            arrows: isOverflow,
                            drag: isOverflow,
                        }
                    })

                    splide.mount()
                }, 0)
            },
            createEmployeeSlider() {
                setTimeout(() => {
                    const splide = new Splide(this.$refs.employeePanel, {
                        gap: 30,
                        perMove: 1,
                        perPage: 4,
                        pagination: false,
                        mediaQuery: 'min',
                        breakpoints: {
                            0: {
                                perPage: 1,
                            },
                            500: {
                                perPage: 2,
                            },
                            1400: {
                                perPage: 3,
                            },
                            1700: {
                                perPage: 4,
                            },
                        },
                    })

                    splide.on('overflow', function (isOverflow) {
                        splide.options = {
                            arrows: isOverflow,
                            drag: isOverflow,
                        }
                    })

                    splide.mount()
                }, 0)
            },
            updateDatePicker() {
                this.datePicker?.destroy()
                this.datePicker = new AirDatepicker(this.$refs.datepicker, {
                    inline: true,
                    locale: localeNL,
                    firstDay: 1,
                    onRenderCell: ({ date, cellType }) => {
                        if (cellType === 'day') {
                            const formattedDate = formatDate(date)

                            if (!(formattedDate in this.bookableTimes)) {
                                return {
                                    disabled: true,
                                    classes: 'disabled-class',
                                }
                            }
                        }
                    },
                    onSelect: ({ date, formattedDate, datepicker }) => {
                        this.invalidateNextSteps()
                        this.filteredTimes = []
                        this.summary.step_three.start_time = ''
                        this.summary.step_three.end_time = ''

                        this.appointmentSteps[this.currentStep - 1].canContinue = false

                        this.filteredTimes = this.bookableTimes[formatDate(date)]
                        const locale = navigator.language || 'nl-NL'
                        const dateChanged = new Date(formatDate(date))

                        const textualDate = new Intl.DateTimeFormat(locale, {
                            weekday: 'short',
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        }).format(dateChanged)

                        this.summary.step_three.textualDate = textualDate
                        this.summary.step_three.date = formatDate(date)

                        this.availableResources = []
                    },
                })
            },

            finishForm() {
                this.currentStep++

                this.appointmentSteps.forEach((step) => {
                    step.state = AppointmentState.FinishedNotEditable
                })
            },

            async submit() {
                if (!this.canSubmit) return

                grecaptcha.ready(async () => {
                    grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(async (recaptchaResponse: any) => {
                        const formData = new FormData()
                        formData.append('response', String(recaptchaResponse))

                        this.isAppointmentLoading = true

                        await fetch('/actions/blitz/csrf/token')
                            .then((result) => {
                                return result.text()
                            })
                            .then(async (response) => {
                                await fetch(validateRecaptchaUrl, {
                                    method: 'POST',
                                    headers: {
                                        'X-CSRF-Token': response,
                                    },
                                    body: formData,
                                })
                                    .then(async (response) => {
                                        if (response.ok) {
                                            const parsedResponse = await response.json()
                                            if (parsedResponse.valid) {
                                                this.recaptchaError = false
                                                this.makeCustomer()
                                            } else {
                                                this.recaptchaError = true
                                                this.isAppointmentLoading = false
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.error('Fetch error:', error)
                                        this.isAppointmentLoading = false
                                    })
                            })
                            .catch((error) => {
                                console.error('Fetch error:', error)
                                this.isAppointmentLoading = false
                            })
                    })
                })
            },

            async makeCustomer() {
                const formData = new FormData()
                formData.append('Voornaam', this.summary.step_five.first_name)
                formData.append('Achternaam', this.summary.step_five.last_name)
                formData.append('E_mail', this.summary.step_five.email)
                formData.append('Adres', this.summary.step_five.address)
                formData.append('Plaats', this.summary.step_five.place)
                formData.append('Postcode', this.summary.step_five.postal_code)
                formData.append('Telefoon', this.summary.step_five.phone)

                await fetch('/actions/blitz/csrf/token')
                    .then((result) => {
                        return result.text()
                    })
                    .then(async (response) => {
                        await fetch(setCustomerUrl, {
                            method: 'POST',
                            headers: {
                                'X-CSRF-Token': response,
                            },
                            body: formData,
                        })
                            .then(async (response) => {
                                if (response.ok) {
                                    const parsedResponse = await response.json()
                                    if (parsedResponse.success) {
                                        this.makeAppointment(parsedResponse.customerId)
                                    } else {
                                        this.hasError = window.translations.errors.customerError
                                        this.isAppointmentLoading = false
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Fetch error:', error)
                                this.isAppointmentLoading = false
                            })
                    })
                    .catch((error) => {
                        console.error('Fetch error:', error)
                        this.isAppointmentLoading = false
                    })
            },

            async makeAppointment(customerId: number) {
                const formData = new FormData()
                formData.append('customerId', String(customerId))
                formData.append('appointmentTypeId', String(this.summary.step_two.location.Id))
                formData.append('resourceId', String(this.summary.step_four.resource.Id))
                formData.append('date', String(this.summary.step_three.date))
                formData.append('startTime', String(this.summary.step_three.start_time))
                formData.append('fields[Ik_ga_de_auto_aanschaffen]', this.summary.step_one.buy_type)
                formData.append('fields[Ik_ben_opzoek_naar_een]', this.summary.step_one.fuel_type)
                formData.append('fields[Kies_hier_het_type_auto_waar_je_interesse_in_hebt]', this.summary.step_one.type)
                formData.append('fields[In_welk_e_model_len_ben_je_ge_nteresseerd]', this.summary.step_one.model)
                formData.append('fields[Ik_wil_proefrijden]', this.summary.step_one.test_drive ? 'Ja' : 'Nee')

                await fetch('/actions/blitz/csrf/token')
                    .then((result) => {
                        return result.text()
                    })
                    .then(async (response) => {
                        await fetch(setAppointmentUrl, {
                            method: 'POST',
                            headers: {
                                'X-CSRF-Token': response,
                            },
                            body: formData,
                        })
                            .then(async (response) => {
                                if (response.ok) {
                                    const parsedResponse = await response.json()
                                    if (parsedResponse.success) {
                                        this.finishForm()
                                    } else {
                                        this.hasError = window.translations.errors.appointmentError
                                        this.isAppointmentLoading = false
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Fetch error:', error)
                                this.isAppointmentLoading = false
                            })
                    })
                    .catch((error) => {
                        console.error('Fetch error:', error)
                        this.isAppointmentLoading = false
                    })
            },
        })
    )
}
