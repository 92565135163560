import Alpine from 'alpinejs'

export const initBasket = () => {
    Alpine.data('basket', () => ({
        error: false,
        isLoading: false,
        sellingPlanId: null,
        async addToBasket(url: String, csrfToken: String, variantId: String) {
            this.isLoading = true

            await fetch('/actions/blitz/csrf/token')
                .then(async (result) => {
                    await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': result.text(),
                        },
                        body: JSON.stringify({ variantId, sellingPlanId: this.sellingPlanId, clearCart: true }),
                    })
                        .then(async (response) => {
                            if (response.ok) {
                                const parsedResponse = await response.json()

                                if (parsedResponse.success) {
                                    window.location.href = parsedResponse.checkoutUrl
                                }
                            } else {
                                this.error = true
                                this.isLoading = false
                            }
                        })
                        .catch((err) => {
                            this.error = true
                            this.isLoading = false
                        })
                })
                .catch((error) => {
                    console.error('Fetch error:', error)
                    this.error = true
                    this.isLoading = false
                })
        },
    }))
}
