import Alpine from 'alpinejs'

// Extending the Window interface to include 'videoScriptLoaded' property
declare global {
    interface Window {
        videoScriptLoaded: boolean
    }
}

export const videoEmbedLoader = (): void => {
    if (window.videoScriptLoaded === undefined) {
        window.videoScriptLoaded = false
    }

    Alpine.data('initLoadVideo', () => ({
        init() {
            const videoEmbed = document.querySelector('[data-video-embed]')

            if (!window.videoScriptLoaded && videoEmbed) {
                const script: HTMLScriptElement = document.createElement('script')
                script.src = 'https://player.vimeo.com/api/player.js'
                script.type = 'text/javascript'
                document.head.append(script)

                // make sure script doesn't execute again
                window.videoScriptLoaded = true
            }
        },
    }))
}
