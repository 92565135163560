import Alpine from 'alpinejs'
import { initBrandFilter } from './filters/brandFilter'
import { initDropdownFilters } from './filters/dropdownFilter'
import { initCheckboxFilters } from './filters/checkboxFilter'

export const initFilters = () => {
    initBrandFilter()
    initDropdownFilters()
    initCheckboxFilters()

    Alpine.data('filters', () => ({
        sort: '' as String | null,
        firstLoop: false,
        isOpen: false,
        spinner: null,
        urlParams: new URLSearchParams(window.location.search),
        init() {
            this.spinner = this.$refs.spinner
            this.$watch('isOpen', (value) => {
                if (value) document.body.classList.add('no-scroll')
                else document.body.classList.remove('no-scroll')
            })

            this.$nextTick(() => {
                if (this.urlParams.size > 0) {
                    let filterAttributes = []
                    Array.from(this.urlParams).forEach(([key, value]) => {
                        if (filterAttributes.includes(key)) return
                        filterAttributes.push(key)
                    })

                    filterAttributes.forEach((key) => {
                        if (this.urlParams.has(key)) {
                            key = key.replace('[]', '')
                            key = key.replace('prijs', 'price')

                            if (key === 'sort') {
                                this.sort = this.urlParams.get(key)
                            }

                            this.$dispatch('toggle-collapsible-' + key)
                        }
                    })
                }
            })
        },
        resetFilters() {
            this.resetURLKeepingSortAndPage()
            this.showSpinner()
        },
        updateSort(e: any) {
            this.sort = e.target.value
            const event = new CustomEvent('update-filter', {
                detail: {
                    attribute: 'sort',
                    value: this.sort,
                    query: 'sort',
                },
            })

            window.dispatchEvent(event)
        },
        updateFilter(event: any) {
            this.showSpinner()
            let qp = new URLSearchParams(window.location.search)

            if (event && event.detail) {
                if (event.detail.attribute != 'sort') {
                    qp.delete('page')
                }
                if (event.detail.attribute == 'dropdown' || event.detail.attribute == 'sort') {
                    qp.set(event.detail.query, event.detail.value)
                } else {
                    if (event.detail.value && event.detail.value.length > 0) {
                        if (qp.get(event.detail.query)) {
                            qp.delete(event.detail.query)
                        }
                        event.detail.value.forEach((value: string) => qp.append(event.detail.query, value))
                    } else {
                        qp.delete(event.detail.query)
                    }
                    if (event.detail.subValues && event.detail.subValues.length > 0) {
                        event.detail.subValues.forEach((subValue: string) => qp.delete(event.detail.subQuery, subValue))
                    }
                }

                window.location.href = window.location.origin + window.location.pathname + '?' + qp.toString()
            }
        },
        showSpinner() {
            if (this.spinner) this.spinner.style.display = 'block'
        },
        checkParams() {
            return (
                this.urlParams.size > 2 ||
                (this.urlParams.size === 2 && !(this.urlParams.has('sort') && this.urlParams.has('page'))) ||
                (this.urlParams.size === 1 && !this.urlParams.has('sort') && !this.urlParams.has('page'))
            )
        },
        getAmount() {
            let count = this.urlParams.size

            if (this.urlParams.has('sort')) {
                count -= 1
            }

            if (this.urlParams.has('page')) {
                count -= 1
            }

            return count
        },
        resetURLKeepingSortAndPage() {
            const url = new URL(window.location.href)
            const params = new URLSearchParams(url.search)

            const sortValue = params.get('sort')
            const pageValue = params.get('page')

            let newUrl = url.origin + url.pathname
            const newParams = new URLSearchParams()

            if (sortValue !== null) {
                newParams.set('sort', sortValue)
            }

            if (pageValue !== null) {
                newParams.set('page', pageValue)
            }

            if (newParams.toString()) {
                newUrl += `?${newParams.toString()}`
            }

            window.location.href = newUrl
        },
    }))
}
