import Alpine from 'alpinejs'

export const initDialog = () => {
    Alpine.data('initDialog', (vestiging) => ({
        init() {
            const dialog = this.$refs.dialog
            const openButton = this.$refs.openButton
            const closeButton = this.$refs.closeButton
            const locationName = this.$refs.locationName

            openButton.addEventListener('click', () => {
                locationName.textContent = vestiging

                //Cant use x-ref, value doesnt exist before initialization
                document.querySelector("[data-fui-id='showroom-vestiging']").value = vestiging
                dialog.showModal()
            })

            closeButton.addEventListener('click', () => {
                dialog.close()
            })

            dialog.addEventListener('click', (event) => {
                let rect = dialog.getBoundingClientRect()
                let isInDialog =
                    rect.top <= event.clientY &&
                    event.clientY <= rect.top + rect.height &&
                    rect.left <= event.clientX &&
                    event.clientX <= rect.left + rect.width
                if (!isInDialog) {
                    dialog.close()
                }
            })
        },
    }))
}
