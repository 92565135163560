import Alpine from 'alpinejs'

export const initCheckboxFilters = () => {
    Alpine.data('checkboxFilter', (id) => ({
        firstLoop: false,
        selected: [] as string[],
        id: '' as string,
        init() {
            this.id = id

            this.ReadFilterFromURL()

            if (!this.firstLoop) {
                this.$watch('selected', () => this.updateFilter())
            }
        },
        updateFilter() {
            const updatedId = this.id.replace(/ /g, '_')
            const event = new CustomEvent('update-filter', {
                detail: {
                    attribute: 'checkbox',
                    value: this.selected,
                    query: updatedId,
                },
            })

            window.dispatchEvent(event)
        },
        ReadFilterFromURL() {
            let urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get(id)) {
                this.selected = urlParams.getAll(id)
            }
            this.firstLoop = false
        },
    }))
}
