/// <reference types="vite/client" />

// Alpine
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)

// AirDatepicker
import 'air-datepicker/air-datepicker.css'

// Css
import '../css/app.css'

// Splide CSS
import '@splidejs/splide/css/core'

// Components
import { initHeader } from './header'
import { initCollapsible } from './collapsible'
import { initIntersectObserver } from './intersect-observer'
import { initJSEssentials } from './js-essentials'
import { initSlider } from './slider'
import { initBasket } from './basket'
import { initDialog } from './dialog'
import { initFilters } from './filters'
import { initVacancyFilter } from './vacancyFilter'
import { initVdpImageSlider } from './vdp-image-slider'
import { videoEmbedLoader } from './video-loader'
import { initSpeculationRules } from './speculation-rules'
import { initOnlineafspraken } from './online-afspraken.ts'
import { initPictureViewer } from './picture-viewer.ts'

initCollapsible()
initHeader()
initIntersectObserver()
initJSEssentials()
initSlider()
initBasket()
initDialog()
initFilters()
initVacancyFilter()
initVdpImageSlider()
videoEmbedLoader()
initSpeculationRules()
initOnlineafspraken()
initPictureViewer()

Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
